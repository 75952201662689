import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroComponent } from './intro/intro.component';
import { MiHomeComponent } from './mi-home.component';
// import { RequestDemoComponent } from './request-demo/request-demo.component';

// import { DataComponent } from './products/data/data.component';
// import { AnalyticsComponent } from './products/analytics/analytics.component';
// import { TechologyComponent } from './products/techology/techology.component';
// import { ServicesComponent } from './products/services/services.component';
import { ProductsComponent } from './products/products.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [{
  path: '', component: MiHomeComponent,
  children: [
    {
      path: 'login',
      component: LoginComponent,
    },
    {
      path: '',
      component: LoginComponent,
    },
    // {
    //   path: 'request-demo',
    //   component: RequestDemoComponent,
    // },
    {
      path: 'products',
      component: ProductsComponent,
    },
    {
      path: 'contact',
      component: ContactUsComponent,
    },
    {
      path: 'about',
      component: AboutComponent,
    },

  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MiHomeRoutingModule { }
